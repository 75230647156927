import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const { headline, reason, employers, academicExperiences } = publicData ;
  const headlineClass = `${css.featuresTitle} ${css.headline}`

  const logos = []
  if (employers && employers.length > 0) {
     employers.forEach((employer) => {
      const { employerDomain } = employer.employer;
      if (employerDomain) {
        logos.push(employerDomain);
      }
    });
  }
  
  if (academicExperiences && academicExperiences.length > 0) {
    academicExperiences.forEach((academicExperience) => {
      const { institutionDomain } = academicExperience.academicExperience;
      if (institutionDomain) {
        logos.push(institutionDomain);
      }
    });
  }
  

  console.log(logos);
  

  console.log(publicData);

  // const selectedOptions = publicData && publicData.yogaStyles ? publicData.yogaStyles : [];
  // const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      <div className={css.miniNav}>
        <a href="#professional-experience">professional experience</a>
        <a href="#academic-experience">academic exeperience</a>
        <a href="#reviews">reviews</a>
      </div>
      <div className={css.logoContainer}>
        {logos.length > 0 && logos.map((logoDomain) => {
          return (
            <img src={`https://logo.clearbit.com/${logoDomain}?size=100"`} className={css.employerImage} onError={(event) => event.target.style.display = 'none'} />
          )
        })}
      </div>
      <h2 className={headlineClass}>
        "{ headline }"
      </h2>
      <h3 className={css.reasonTitle}>why i do it?</h3>
      <p className={css.reason}>{ reason }</p>
      <div className={css.section}>
        {employers && employers.length > 0 &&
          <>
            <h2 id="professional-experience" className={css.featuresTitle}>
              professional experience
        </h2>
            {employers.map((item, index) => {
              const { employerName, employerDomain, position, sector, achievements, employerDate, employerStartDate, employerEndDate } = item.employer;
              return (
                <div className={css.employerContainer} key={`employer-${index}`}>
                  <div className={css.employerInnerContainer}>
                    {employerDomain && <div className={css.employerImageContainer}>
                      <img src={`https://logo.clearbit.com/${employerDomain}?size=100"`} className={css.employerImage} onError={(event) => event.target.parentElement.style.display = 'none'} />
                    </div>}
                    <div className={css.employerInfoContainer}>
                      {employerName && <p className={css.employerName}>{employerName}</p>}
                      {sector && <p className={css.employerSector}>{sector}</p>}
                      {position && <p className={css.position}>{position}</p>}
                      {/* {employerDate && <p className={css.employerDate}>{employerDate}</p>} */}
                      {employerStartDate &&
                        <p className={css.employerDate}>
                          <span>{employerStartDate}</span>
                          {employerEndDate && <span> - {employerEndDate}</span>}
                          {!employerEndDate && <span> - present</span>}
                        </p>
                      }
                    </div>
                  </div>

                  {achievements && <p className={css.employerAchievements}>{achievements}</p>}
                </div>
              )
            })}
          </>
        }
      </div>

      <div className={css.section}>
        {academicExperiences && academicExperiences.length > 0 &&
          <>
            <h2 id="academic-experience" className={css.featuresTitle}>
              academic experience
          </h2>
            {academicExperiences.map((item, index) => {
              const { institution, institutionDomain, sector, subject, degreeType, undergradOrPostgrad, years, accolades } = item.academicExperience;
              return (
                <div className={css.academicContainer} key={`academic-${index}`}>
                  <div className={css.academicInnerContainer}>
                    {institutionDomain && <div className={css.academicImageContainer}>
                      {institutionDomain && <img src={`https://logo.clearbit.com/${institutionDomain}?size=100"`} className={css.academicImage} onError={(event) => event.target.parentElement.style.display = 'none'} />}
                    </div>}
                    <div className={css.academicInfoContainer}>
                      {institution && <p className={css.institution}>{institution}</p>}
                      <div className={css.subjectContainer}>
                        {degreeType && <p className={css.degreeType}>{degreeType}</p>}
                        {subject && <p className={css.subject}>{subject}</p>}
                      </div>
                      {sector && <p className={css.academicSector}>{sector}</p>}
                      {undergradOrPostgrad && <p className={css.undergradOrPostgrad}>{undergradOrPostgrad}</p>}
                      {years && <p className={css.academicYears}>{years}</p>}
                    </div>
                  </div>
                  {accolades && <p className={css.accolades}>{accolades}</p>}
                </div>
              )
            })}
          </>
        }
      </div>
      
      
      {/* <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.yogaStyles"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      /> */}
    </div>
  );
};

export default SectionFeaturesMaybe;
